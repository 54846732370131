<template>
    <div class="common">
        <!--header-->
        <el-row type="flex" justify="space-between" align="middle" class="commonTop">
            <el-row type="flex" align="middle" class="name">
                <router-link :to="{ name: '引导页'}">天缘易购商城后台管理系统</router-link>
            </el-row>
            <el-row type="flex" justify="space-between" align="middle" class="nav">
                <el-row class="user">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link flex flex-ai-c">
                            {{adminInfo.username}}
                            <i class="el-icon-arrow-down" style="margin-left:5px"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-s-tools" command="setting" @click.native="goindex()">
                                进入前台
                            </el-dropdown-item>
                            <el-dropdown-item icon="el-icon-switch-button" @click.native="logOut()">
                                退出登录
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </el-row>
            </el-row>
        </el-row>

        <!--left-->
        <div class="commonLeft" :style="{'width':isCollapse?'64px':'200px'}">
            <div class="nav">
                <el-aside>
                    <el-row type="flex" justify="center" class="headImg">
                        <img :src="require('../../assets/avatars2.jpg')" :style="{'width':isCollapse?'42px':'60px'}" />
                        <p v-if="!isCollapse">{{adminInfo.name}}</p>
                    </el-row>
                    <el-menu background-color="#001529" text-color="#EBF2FF" :default-active="$route.path" :unique-opened="true" active-text-color="#fff" :router="true" :collapse="isCollapse" style="height:calc( 100% - 184px );overflow-y: auto;overflow-x: hidden;">
                        <template v-for="nav in navList">
                            <el-menu-item :index="nav.path" :key="nav.name" _click="navTo(nav.path)" v-if="!nav.sub">
                                <i :class="nav.icon" v-if="nav.icon"></i>
                                <span slot="title">{{nav.name}}</span>
                            </el-menu-item>

                            <el-submenu :index="nav.path" :key="nav.name" v-if="nav.sub">
                                <template slot="title">
                                    <i :class="nav.icon" v-if="nav.icon"></i>
                                    <span>{{nav.name}}</span>
                                </template>
                                <template v-for="item in nav.sub">
                                    <el-menu-item :index="item.path" :key="item.name" v-if="!item.sub">
                                        <i :class="item.icon" v-if="item.icon"></i>
                                        {{item.name}}
                                    </el-menu-item>

                                    <el-submenu :index="item.path" :key="item.name" v-if="item.sub">
                                        <template slot="title">
                                            <i :class="item.icon" v-if="item.icon"></i>
                                            <span>{{item.name}}</span>
                                        </template>
                                        <el-menu-item v-for="item2 in item.sub" :index="item2.path" :key="item2.name">
                                            <i :class="item2.icon" v-if="item2.icon"></i>
                                            {{item2.name}}
                                        </el-menu-item>
                                    </el-submenu>
                                </template>
                            </el-submenu>
                        </template>
                        <p style="height:50px;background:#001529"></p>
                    </el-menu>

                    <div class="menuBtnbox" :style="{'width':isCollapse?'64px':'200px'}">
                        <i class="el-icon-s-operation menuBtn" @click="handleCollapse()"></i>
                    </div>
                </el-aside>
            </div>
        </div>
        <!--right-->
        <div class="commonRight" :style="{'left':isCollapse?'64px':'200px'}">
            <router-view :isCollapse="isCollapse" />
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data: function () {
        return {
            isCollapse: false,
            adminInfo: {},
            navList: [{
                name: '角色管理',
                path: '/setRole',
                icon: 'el-icon-menu',
            }, 
            // {
            //     name: '节点管理',
            //     path: '/setNode',
            //     icon: 'el-icon-menu',
            // }, 
            {
                name: '权限管理',
                path: '/setJurisdiction',
                icon: 'el-icon-menu',
            }, {
                name: '人员管理',
                path: '/setPersonnel',
                icon: 'el-icon-menu',
            }],
        }
    },
    mounted: function () {
        this.adminInfo = localStorage.getItem('adminInfo') ? JSON.parse(localStorage.getItem('adminInfo')) : this.adminInfo;
    },
    //导航守卫
    beforeRouteEnter(to, from, next) {
        Promise.all([
            axios.post(window.domain + '/admin/admin/loginInfo')
        ]).then(result => next(vm => {
            // 執行結果會按照上面順序放在 result 數組內，所以 result[0]，代表第一個函數的 resolve 結果
            if (result[0].data.code == '0000') vm.$root.userInfo = result[0].data.data;
        }))
    },
    methods: {
        goindex: function () {
            this.$router.push('/guide')
        },

        //nav跳转
        navTo: function (path) {
            this.$router.push(path)
        },

        handleCollapse: function () {
            this.isCollapse = !this.isCollapse
        },

        logOut: function () {
           this.http.post("/login/logout").then(() => {
                localStorage.clear();
                this.$router.push("/");
            });
        }
    },
}
</script>
